import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage.js';

import RectangleLink from '../../components/RectangleLink';
import Clarification from '../../components/Clarification';

import enMessages from '../../i18n/legal/infoRates/en.js'
import ruMessages from '../../i18n/legal/infoRates/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const InformationOnInterestRatesPage = ({ pathContext: { locale } }) => {
    const headerStyleNumb = '10';
    const section = 'infoRates';

    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      if (locale === 'en'){
        window.location.replace('/en/legal/requisite');
      }
    }

    return (
      <TemplateLegalPage
        headerStyleNumb={headerStyleNumb}
        locale={locale}
        section={section}
        messages={messages[locale]}
        messagesMain={messagesMain[locale]}
    > 
    {messages[locale]?.content?.map((item) => {
        return (
          <React.Fragment key={item.link}>
            {item.title ? (
              <RectangleLink
                title={item.title}
                label={item.label}
                link={item.link}
                target="_blank"
              />
            ) : null}

            {item.clarification ? (
              <Clarification
                label={item.clarification.label}
                text={item.clarification.text}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </TemplateLegalPage>   
    )
};

export default InformationOnInterestRatesPage;
